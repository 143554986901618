import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const ArtDirection = makeShortcode("ArtDirection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`We add digital value to your business`}</h2>
    <PageDescription mdxType="PageDescription">
    We create appealing and functional ecommerce experiences for remarkable brands on shopify.
    </PageDescription>
    <FeatureCard subtitle="Services" title="Shopify packages" href="/services/shopify-packages" actionIcon="arrowRight" color="dark" mdxType="FeatureCard">
      <ArtDirection mdxType="ArtDirection">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgT/2gAMAwEAAhADEAAAAXayW0xf/8QAGRAAAQUAAAAAAAAAAAAAAAAAAQIDEyAx/9oACAEBAAEFAkMxAZT/xAAWEQEBAQAAAAAAAAAAAAAAAAACEBL/2gAIAQMBAT8BAzP/xAAXEQADAQAAAAAAAAAAAAAAAAABAhAR/9oACAECAQE/AWOz/8QAFhABAQEAAAAAAAAAAAAAAAAAMSBh/9oACAEBAAY/Ah2v/8QAGxABAAICAwAAAAAAAAAAAAAAAREhEDEAcZH/2gAIAQEAAT8hVZQoibecUi5xvd95/9oADAMBAAIAAwAAABBP/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQMBAT8Q0yLN/8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQIBAT8QWk//xAAbEAEAAQUBAAAAAAAAAAAAAAABEQAQITFhQf/aAAgBAQABPxBiJQEpz4wHaJBAm4i0E0yXqxqv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Innovation, UX and new technologies",
            "title": "Innovation, UX and new technologies",
            "src": "/static/69ac3d49ea0c8191e6768340744ced92/2e753/ecommerce-service-mobile.jpg",
            "srcSet": ["/static/69ac3d49ea0c8191e6768340744ced92/69549/ecommerce-service-mobile.jpg 288w", "/static/69ac3d49ea0c8191e6768340744ced92/473e3/ecommerce-service-mobile.jpg 576w", "/static/69ac3d49ea0c8191e6768340744ced92/2e753/ecommerce-service-mobile.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgT/2gAMAwEAAhADEAAAAXayW0xf/8QAGRAAAQUAAAAAAAAAAAAAAAAAAQIDEyAx/9oACAEBAAEFAkMxAZT/xAAWEQEBAQAAAAAAAAAAAAAAAAACEBL/2gAIAQMBAT8BAzP/xAAXEQADAQAAAAAAAAAAAAAAAAABAhAR/9oACAECAQE/AWOz/8QAFhABAQEAAAAAAAAAAAAAAAAAMSBh/9oACAEBAAY/Ah2v/8QAGxABAAICAwAAAAAAAAAAAAAAAREhEDEAcZH/2gAIAQEAAT8hVZQoibecUi5xvd95/9oADAMBAAIAAwAAABBP/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQMBAT8Q0yLN/8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQIBAT8QWk//xAAbEAEAAQUBAAAAAAAAAAAAAAABEQAQITFhQf/aAAgBAQABPxBiJQEpz4wHaJBAm4i0E0yXqxqv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Innovation, UX and new technologies",
            "title": "Innovation, UX and new technologies",
            "src": "/static/69ac3d49ea0c8191e6768340744ced92/2e753/ecommerce-service-tablet.jpg",
            "srcSet": ["/static/69ac3d49ea0c8191e6768340744ced92/69549/ecommerce-service-tablet.jpg 288w", "/static/69ac3d49ea0c8191e6768340744ced92/473e3/ecommerce-service-tablet.jpg 576w", "/static/69ac3d49ea0c8191e6768340744ced92/2e753/ecommerce-service-tablet.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgT/2gAMAwEAAhADEAAAAXayXUxX/8QAGRAAAQUAAAAAAAAAAAAAAAAAAgEDEyAx/9oACAEBAAEFAgZiFMp//8QAFhEBAQEAAAAAAAAAAAAAAAAAAhAS/9oACAEDAQE/AQMz/8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQEf/aAAgBAgEBPwFjs//EABYQAQEBAAAAAAAAAAAAAAAAADEgYf/aAAgBAQAGPwIdr//EABkQAAIDAQAAAAAAAAAAAAAAAAERECExAP/aAAgBAQABPyFoyBIJ24mFuN25/9oADAMBAAIAAwAAABDL/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQMBAT8Q0yLN/8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQIBAT8QWk//xAAaEAEAAwEBAQAAAAAAAAAAAAABESExABBh/9oACAEBAAE/EGQTgJTehQfeJBAmxHiF7tlvwzv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Innovation, UX and new technologies",
            "title": "Innovation, UX and new technologies",
            "src": "/static/375f44065a53e71b166df5bb01f6316b/2e753/ecommerce-service.jpg",
            "srcSet": ["/static/375f44065a53e71b166df5bb01f6316b/69549/ecommerce-service.jpg 288w", "/static/375f44065a53e71b166df5bb01f6316b/473e3/ecommerce-service.jpg 576w", "/static/375f44065a53e71b166df5bb01f6316b/2e753/ecommerce-service.jpg 1152w", "/static/375f44065a53e71b166df5bb01f6316b/e2fe3/ecommerce-service.jpg 1424w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ArtDirection>
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      